
export const malayu = {

    //menu
    home: "Rumah",
    dashboard: "Papan pemuka",
    data: "data",
    orders: "Pesanan",
    delivery_order: "Pesanan penghantaran",
    invoice: "Invois",
    master_data: "Data induk",
    client: "Pelanggan",
    product: "produk",
    category: "kategori",
    uom: "Uom",
    inventory: "Inventori",
    product_inventory: "Inventori produk",
    inventory_transactions: "Transaksi Inventori",
    configuration: "Konfigurasi",
    settings: "tetapan",
    currency: "mata wang",
    terms: "Syarat",
    term_type: "Jenis Istilah",
    company: "Syarikat",
    company_profile: "Profil Syarikat",
    company_users: "Pengguna Syarikat",
    admin_area: "Kawasan admin",

    //system admin menu, no translation required
    system_admin_area: "System Admin Area",
    tenant_maintenance: "Tenant Maintenance",
    invoice_do: "Invoice/Do",
    tenant_management: "Tenant Management",
    tenant: "Tenant",
    users: "Users",
    api_configuration: "API Configuration",
    subscription_package: "Subscription Package",
    servicegroup: "Servicegroup",
    services: "Services",
    scheduler: "Scheduler",
    scheduler_log: "Scheduler Log",

    //header
    all_notification: "Semua Pemberitahuan",
    hi: "Hai",
    account_settings: "Tetapan Akaun",
    logout: "Log keluar",
    support_sent_message: "Permintaan sokongan dihantar kami akan membalas e-mel anda",
    support_request: "Permintaan Sokongan",
    support_request_info: "Beritahu kami bagaimana kami boleh menyokong permintaan anda; mengalu-alukan semua cadangan juga.",
    more_contents_error: "Sila masukkan lebih banyak kandungan",


    //generic
    ok: "Okey",
    to: "Kepada",
    yes: "ya",
    no: "Tidak",
    create: "Buat",
    cancel: "Batal",
    delete: "Padam",
    update: "Kemas kini",
    unknow_error: "Ralat berlaku sila hubungi sokongan.",
    data_failed_to_load: "Data gagal dimuatkan.",
    please_confirm: "Tolong sahkan.",
    invalid_email_format: "Format E-mel Tidak Sah.",
    rows_per_page: "Baris setiap halaman",
    record_deleted: "Rekod Dipadamkan.",
    record_created: "Rekod Dibuat.",
    record_updated: "Rekod Dikemaskini.",
    record_delete_message: "Anda akan memadamkan rekod sila sahkan.",
    saved: "disimpan",


    //create tenant
    company_created: "Syarikat dicipta!",
    company_created_prompt: "Anda telah mencipta syarikat anda sila log masuk semula unt",
    create_company: "Buat Syarikat",
    create_company_first_step: "Langkah pertama pendigitalan anda",
    create_company_fill_info: "Isi semua maklumat",
    create_company_in_eceipt: "Buat syarikat anda dalam Eceipt",
    create_company_manage_order: "Uruskan pesanan",
    create_company_manage_inventory: "Urus inventori",
    create_company_save_cost: "Jimat Kos",
    company_name: "nama syarikat",
    tenant_code_exist: "Kod Penyewa Wujud",
    company_code: "Kod syarikat",
    company_introduction: "Pengenalan Syarikat",
    representative: "wakil rakyat",
    business_registration_number: "Nombor Pendaftaran Perniagaan",
    contact_email: "E-mel Hubungi",
    contact_number: "Nombor telefon",
    fax: "Faks",
    address_line_1: "Alamat Baris 1",
    address_line_2: "Alamat 2",
    address_line_3: "Talian Alamat 3",
    address_line_4: "Talian Alamat 4",
    country: "Negara",
    postcode: "Poskod",

    //table column name
    id: "ID",
    nr: "No",
    "on hand": "dalam tangan",
    name: "nama",
    description: "penerangan",
    quantity: "kuantiti",
    unit: "unit",
    remark: "teguran",
    date: "Tarikh",
    message: "mesej",
    total: "jumlah",
    unitprice: "harga seunit",
    safetystocklevel: "Stok keselamatan",
    safetystocklevelInfo: "Letakkan Stok Keselamatan lebih daripada Sifar untuk dipaparkan dalam papan pemuka.",
    type: "menaip",
    shortname: "nama pendek",
    movement: "pergerakan",
    completed_on: "pada",
    do: "buat",
    by: "Oleh",
    firstname: "nama pertama",
    lastname: "nama terakhir",
    roles: "peranan",
    provider: "pembekal",
    mode: "mod",
    amount: "jumlah",
    onhand: "dalam tangan",
    reserved: "terpelihara",
    in_transit: "dalam trans",

    //dashboard
    overdue: "tertunggak",
    outstanding_invoice: "Invois Tertunggak",
    dashboard_of: "daripada",
    outstanding_delivery_order: "Pesanan Penghantaran Tertunggak",
    invoice_history: "Sejarah Invois",
    order_count: "Kiraan Pesanan",
    order_amount: "Jumlah Pesanan",
    delivery_order_history: "Sejarah Pesanan Penghantaran",
    normal: "Biasalah",
    low: "rendah",
    nostock: "Tiada stok",
    code: "Kod",
    status: "Status",

    //orser status
    ALL: "SEMUA",
    DRAFT: "DRAF",
    GENERATED: "DIJANA",
    INVOICED: "DIINVOIS",
    SETTLED: "SELESAI",
    CANCELLED: "DIBATALKAN",
    TRANSIT: "TRANSIT",
    DELIVERED: "DIHANTAR",
    ORDER: "PESANAN",
    ADJUSTMENT: "PELARASAN",


    //orders
    transaction_success: "Kejayaan Transaksi.",
    generate_pdf_success: "PDF berjaya dijana.",
    resend_email_success: "E-mel berjaya dihantar semula.",
    confirm_delivery_order1: "Anda akan mengesahkan penghantaran barang kepada pelanggan.",
    confirm_delivery_order2: "Setelah disahkan anda tidak akan dapat menukar kandungan pa",
    confirm_resend_email1: "Anda akan menghantar semula pesanan PDF kepada pelanggan.",
    confirm_resend_email2: "E-mel akan dihantar ke alamat e-mel pelanggan sila masukkan",
    confirm_goods_received: "Sila sahkan semua barang yang diterima.",
    ship: "kapal",
    ship_send_email: "kapal & E-mel",
    email: "E-mel",
    send: "Hantar",
    received: "Menerima",
    cancel_order_warning: "Anda akan membatalkan pesanan apabila pesanan dibatalkan, p",
    cancel_order: "Batalkan Pesanan",
    manage_delivery_order: "Uruskan Pesanan Penghantaran",
    search_order_number: "Cari Nombor Pesanan",

    order_number: "Nombor Pesanan",
    order_to: "Kepada",
    delivery_date: "Tarikh penghantaran",

    view_pdf: "Lihat PDF",
    pdf_generating: "Penjanaan PDF",
    regenerate_pdf_message: "Klik untuk menjana semula jika tiada PDF dijana",
    action: "Tindakan",
    edit: "Sunting",
    revise: "Semak semula",
    invoice_send_email:"Invois & E-mel",
    receive: "terima",
    update_linked_invoice: "Kemas kini Invois Terpaut",
    data_preview_alert: "Pratonton data hanya PDF akan mempunyai format yang berbeza",
    from: "daripada",
    phone: "telefon",
    brn: "BRN",
    attn_to: "Perhatian kepada",
    invoice_number: "Nombor invois",
    total_quantity: "Kuantiti keseluruhan",
    loading_company_info: "Memuatkan Maklumat Syarikat",
    loading_existing_client: "Memuatkan Klien Sedia Ada",
    loading_existing_open_invoice: "Memuatkan Invois terbuka sedia ada",
    do_saved: "Pesanan Penghantaran Disimpan",
    fill_all_field_warning: "Sila isi semua medan untuk meneruskan",
    finalise_do_message: "Anda sedang memuktamadkan pesanan penghantaran ini sila sah",
    generate_order_number_message: "Nombor pesanan akan dijana dan tidak akan dapat ditetapkan",
    finalize: "memuktamadkan",
    create_delivery_order: "Buat Pesanan Penghantaran",
    linked_invoice_number: "Nombor Invois Terpaut",
    none: "tiada",
    select_client: "Pilih Klien",
    add_product: "Tambah produk anda",
    add_terms: "Tambah terma dan syarat",
    save_draft: "Menyimpan draf",
    preview_pdf: "Pratonton PDF",
    remarks: "Kenyataan",
    linked_invoice: "Invois Terpaut",
    do_inv_mismatch_error: "Kuantiti Pesanan Penghantaran adalah lebih daripada kuantit",
    current_do: "Pesanan Penghantaran Semasa",
    previous_do: "Pesanan Penghantaran Terdahulu",
    invoice_to_client_message1: "Anda akan membuat invois kepada pelanggan. E-mel dengan PDF",
    invoice_to_client_message2: "Setelah diinvois, anda tidak akan dapat menukar kandungan p",
    payment_received_confirmation: "Sila sahkan semua pembayaran yang diterima.",
    paid: "Dibayar",
    manage_invoice: "Urus Invois",
    invoice_date: "Tarikh invois",
    resend_email: "Menghantar semula e-mel",
    generate_delivery_order: "Hasilkan Pesanan Penghantaran",
    create_invoice: "Buat Invois",
    payment_due_date: "Tarikh Akhir Pembayaran",
    total_amount: "Jumlah keseluruhan",
    invoice_saved: "Invois Disimpan.",
    finalise_invoice_message: "Anda sedang memuktamadkan pesanan penghantaran ini sila sah",
    enable_inv_trans: "Dayakan Transaksi Inventori",
    enable_inv_trans_msg: "Dayakan pilihan ini supaya transaksi inventori akan dijana.",
    invoice_multi_currency_error: "Satu invois hanya membenarkan satu mata wang!",

    //master
    client_Name: "nama pelanggan",
    client_short_Name: "Nama Pendek Pelanggan",
    manage_client: "Urus Pelanggan",
    manage_product: "Uruskan Produk",
    manage_category: "Uruskan Kategori",
    manage_uom: "Urus Uom",
    manage_currency: "Urus Mata Wang",
    manage_terms: "Urus Syarat",
    manage_term_type: "Urus Jenis Istilah",

    //inventory
    manage_product_inventory: "Urus Inventori Produk",
    number_more_than_zero_error: "Kuantiti hendaklah lebih daripada Sifar",
    inventory_adjustment: "Pelarasan Inventori",
    in: "dalam",
    out: "keluar",
    adjust: "melaraskan",
    transaction_added: "Transaksi Ditambah.",
    view_product_inventory: "Lihat Inventori Produk",
    search_product_code: "Cari Kod Produk",

    //settings
    platform_setting: "Tetapan Platform",
    document_language: "Bahasa Dokumen",
    english: "Inggeris",
    chinese: "cina",
    language_setting_message: "Bahasa yang digunakan untuk PDF yang dijana.",
    order_setting: "Tetapan Pesanan",
    invoice_order_format: "Format Pesanan Invois",
    preview: "Pratonton",
    delivery_order_format: "Format Pesanan Penghantaran",
    sequence: "Urutan",
    pdf_format: "Format PDF",
    style_1: "Format 1",
    style_2: "Format 2",
    attention:"Perhatian",
    save_setting_message:'Menyimpan dengan format invois yang berbeza akan menetapkan semula urutan pesanan, menyebabkan rekod tidak konsisten dalam pesanan invois.',

    //User Profile
    user_info_updated: "Maklumat Pengguna Dikemaskini.",
    image_updated: "Imej dikemas kini.",
    image_remove: "Imej Dialih Keluar.",
    password_updated: "Kata Laluan Dikemaskini.",
    password_strength_warning: "Kekuatan kata laluan terlalu rendah.",
    file_size_too_big_error: "Saiz fail terlalu Besar. Sila gunakan imej yang lebih kecil",
    remove_image_prompt: "Anda akan mengalih keluar imej sila sahkan.",
    change_password: "Tukar kata laluan",
    change_password_google_message: "*untuk pengguna log masuk google anda boleh menggunakan fun",
    old_password: "Kata laluan lama",
    new_password: "Kata laluan baharu",
    password_requirement: "Format kata laluan tidak sah. (Minimum 8 aksara dengan camp",
    update_user_info: "Kemas kini Maklumat Pengguna",
    user_profile: "Profil pengguna",
    verifed: "Disahkan",
    recent_login_on: "Log Masuk Terkini pada",
    login_count: "Kiraan Log Masuk",

    //manage company
    user_invited: "Pengguna Dijemput.",
    user_removed: "Pengguna Dialih Keluar.",
    user_removed_message: "Anda akan mengalih keluar pengguna daripada penyewa sila sa",
    remove: "keluarkan",
    invite_user: "Jemput Pengguna",
    invite_user_message: "*E-mel akan dihantar kepada pengguna alamat e-mel ini akan",
    invite: "Jemput",
    password: "Kata laluan",
    user: "pengguna",
    tenant_admin: "ADMIN PENYEWA",
    remove_user_from_tenant: "Alih Keluar Pengguna Daripada Penyewa",
    update_company_info: "Kemas kini Maklumat Syarikat",
    owner: "Pemilik",
    owner_firstname: "Nama Pertama Pemilik",
    owner_lastname: "Nama Keluarga Pemilik",
    company_info: "Maklumat Syarikat",
    upload_company_logo: "Muat naik Logo Syarikat",
    owner_email: "E-mel Pemilik",
    introduction: "pengenalan",
    subscription_cancelled: "Langganan Dibatalkan.",
    subscription_cancel_message: "Anda akan membatalkan langganan penyewa.",
    company_subscription: "Langganan Syarikat",
    subscribe: "Langgan",
    extend_expiry_date: "Lanjutkan Tarikh Luput",
    expire_date: "Tarikh luput",
    health: "Kesihatan",
    payment_provider: "Pembekal Pembayaran",
    payment_mode: "Mod Pembayaran",
    one_time_payment: "Bayaran Sekali",
    subscription: "Langganan",
    recurring_payment_status: "Status Bayaran Berulang",
    cancel_subscription: "Batalkan Langganan",
    payment_date: "Tarikh pembayaran",
    customer_email: "E-mel Pelanggan",
    customer_id: "ID pelanggan",
    subscription_id: "Id Langganan",
    monthly: "Bulanan",
    transaction_history: "sejarah transaksi",
    success: "Kejayaan",

    platform_announcement: "Pengumuman Platform",
    view: "Semak",
}